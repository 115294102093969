@media (min-width: 1910px) {
}

@media (min-width: 1910px) and (min-height: 1070px) {
}

@media (max-width: 1910px) and (min-height: 890px) {
}

@media (max-width: 1550px) {
    .header__account__user p {
        text-overflow: ellipsis;
        margin-right: 12px;
        overflow: hidden;
        white-space: nowrap;
        width: 25%;
    }
}

@media (max-width: 1450px) and (min-height: 880px) {
}

@media (max-width: 1450px) {
    .sendResumeModal {
        top: 1%;
    }
}

@media (max-width: 1380px) {
    .main__wrap {
        margin: 0px 10%;
    }
    .searchWork {
        padding: 37px 0 40px 10%;
    }
    .sendResumeModal__resume {
        margin-bottom: 15px;
    }
    .sendResumeModal__resumeSelect {
        margin-bottom: 10px;
    }
    .sendResumeModal__message {
        margin-bottom: 10px;
    }
    .sendResumeModal__message {
        margin-bottom: 15px;
    }
    .sendResumeModal__title h4 {
        margin-bottom: 0;
    }
    .sendResumeModal__title {
        margin-bottom: 15px;
    }
    .sendResumeModal__wrap form {
        margin-bottom: 0;
    }
    .sendResumeModal {
        top: 3%;
        overflow-y: scroll;
        max-height: 100vh;
    }
    .sendResumeModal::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }
    .sendResumeModal::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid transparent;
        background-clip: padding-box;
        border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .sendResumeModal::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    .sendResumeModal::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    .login__form {
        margin-bottom: 0;
    }
    section.register__employer {
        height: auto;
        padding: 10px 0;
        box-sizing: border-box;
    }
    section.register__applicant .register__form__box {
        margin-bottom: 0;
    }
    .login__form_btn {
        margin-bottom: 20px;
    }
}

@media (max-width: 1100px) {
    .main__wrap {
        margin: 0 3%;
    }
    .header__nav nav a {
        padding: 0 10px;
        font-size: 14px;
    }
    .header__account {
        margin-left: 0;
    }
    .header__account__user {
        margin-left: 20px;
    }
    .header__account_active a {
        font-size: 14px;
    }
}

@media (max-width: 900px) {
    body {
        overflow-x: visible;
    }
    .PArateBox__btn__wrap {
        flex-direction: column-reverse;
        width: 100%;
    }
    span.attentionBlock {
        margin: 0 0 0 auto;
        margin-top: 20px;
    }
    img.homeBG_left {
        width: 100%;
    }
    span.attentionPrompt {
        top: 62px;
    }
    label.hideResumeMobil {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gr300);
        padding: 12px 22px;
        margin-bottom: 24px;
    }
    label.hideResumeMobil:first-child {
        margin-bottom: 8px;
    }
    label.hideResumeMobil p {
        margin-right: 6px;
    }
    .resumeVacanciesHideBlock {
        width: 100%;
        height: 42%;
        justify-content: center;
        align-items: flex-start;
    }
    .eyeHideIcon {
        transform: unset;
        transform: translateY(-34px);
    }
    .car_rights__box {
        margin-right: 8px;
        margin-bottom: 8px !important;
    }
    .resumeCompressBox__title__info p {
        margin-top: 0;
    }
    .resumeCompressBox__title__info a {
        margin-bottom: 10px;
        width: 60%;
    }
    .resumeCompressBox__btn p.btns {
        width: 100%;
        margin: 0 0 10px 0;
    }
    .login img.showPassBtn {
        bottom: 12px;
    }
    .register__wrap {
        width: 100%;
        min-width: unset;
    }
    .register__form form {
        flex-direction: column;
    }
    .register__form__box {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px !important;
    }
    .catBox__title {
        white-space: normal;
    }
    .addResume {
        width: 100%;
        position: relative;
    }
    .addVacancy {
        width: 100%;
    }
    .langAdd__input {
        display: flex;
        flex-direction: column;
    }
    .langAdd__input select {
        width: 100%;
        margin-bottom: 10px;
    }
    .vacancyCompressBox__info .resumeCompressBox__btn a {
        margin-right: 0;
        width: 100%;
    }
    .vacancyCompressBox__info .resumeCompressBox__btn form {
        width: 100%;
        margin-right: 0;
    }
    .vacancyCompressBox__info
        .resumeCompressBox__btn
        form
        .PArateBox__left_refusal {
        width: 100%;
        margin-bottom: 20px;
    }
    .vacancyCompressBox__info .resumeCompressBox__btn object {
        margin-right: 0;
        width: 100%;
    }
    .deleteOffersModal {
        width: 95%;
        left: 2.5%;
    }
    .resumeCompressBox__btn object {
        width: 100%;
    }
    .form__filtersBox__check {
        display: flex;
        flex-direction: column;
    }
    .form__filters__open {
        height: auto;
        margin-bottom: 0;
    }
    .header__nav {
        display: none;
    }
    // .header__account a:first-child {
    //     display: none;
    // }
    .main__wrap {
        margin: 0px 3%;
    }
    .header__account__user p {
        display: none;
    }
    .header__account__user .accountArrDown {
        display: none;
    }
    .PAtoggle__wrap a {
        width: 22.91%;
        display: flex;
        flex-direction: column;
        padding: 8px 13px;
    }
    .PAtoggle__wrap a p {
        font-size: 12px;
    }
    .PAtoggle__wrap a svg {
        margin-right: 0;
        margin-bottom: 10px;
    }
    p.addVacancyInfo {
        width: 100%;
    }
    .PA__dropContent {
        flex-direction: column;
    }
    .PAavatar__img {
        width: 80px;
        height: 80px;
        z-index: 1;
    }
    .PAavatar__img__employer {
        width: 158px;
        height: 158px;
    }
    .PA__dropContent .dropContent__big {
        width: 100%;
    }
    .dropContent__small {
        width: 100%;
    }
    .PAavatar {
        margin-bottom: 30px;
    }
    .PAavatar__wrap {
        width: 80%;
    }
    .PAavatar__substrate__box {
        justify-content: center;
    }
    .PAavatar__substrate {
        width: 90%;
        transform: translateX(16%);
        height: 80px;
        border-top-left-radius: 0%;
        border-top-right-radius: 0%;
        align-items: center;
        border-radius: 11px;
    }
    .PAavatar__substrate__employer {
        height: 150px;
    }
    .createResumeBox__wrap {
        display: flex;
        flex-direction: column;
    }
    .createResumeBox__wrap img {
        width: 26%;
        margin: 0;
        margin-bottom: 53px;
    }
    .createResumeBox {
        padding: 30px;
    }
    .createResumeBox__text {
        text-align: center;
    }
    .createResumeBox__text h2 {
        margin-bottom: 15px;
        font-size: 24px;
    }
    .createResumeBox__text p {
        margin-bottom: 40px;
        color: var(--sec200);
    }
    .createResumeBox__wrap a {
        margin: 0 auto;
    }
    .personalData form .btns {
        width: 100%;
    }
    .advertisingFull {
        width: 100%;
    }
    .advertisingFull img {
        width: 100%;
        object-fit: cover;
    }
    .footer__top {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 40px 20px;
        box-sizing: border-box;
    }
    .footer__top__box {
        margin-bottom: 36px;
    }
    .footer__top__box p {
        font-size: 20px;
    }
    .footer__top__box a {
        font-size: 16px;
        width: fit-content;
    }
    .footer__top__box__inline a img {
        width: 150%;
    }
    .footer__top__box__inline a {
        margin-right: 28px;
    }
    .footer__bottom {
        display: flex;
        flex-direction: column;
    }
    .footer__bottom__boxCompany,
    .footer__bottom__box {
        margin-bottom: 30px;
    }
    .footer__bottom {
        height: auto;
        padding: 20px 0;
        box-sizing: border-box;
    }
    .footer__bottom__boxPolicy {
        margin-bottom: 0;
    }
    footer hr {
        background: #ced2d4;
        border: none;
    }
    .PAavatar__img__wrap label {
        bottom: -9px;
        right: 32%;
    }
    .PAavatar__substrate__box,
    .changeStatus {
        transform: translateY(48px);
    }
    .PAavatar__substrate__open {
        height: 122px;
    }
    .userStatusOpen {
        transform: translateY(10px);
    }
    .responsesToggle {
        display: flex;
        flex-direction: column;
    }
    .responsesToggle__box {
        width: 100%;
        margin-bottom: 6px;
        padding: 20px 16px;
        box-sizing: border-box;
        height: 52px;
    }
    .responsesToggle__box p {
        font-weight: 500;
        font-size: 16px;
        line-height: 18.75px;
    }
    .responsesToggle__box img {
        width: 20px;
        height: 20px;
    }
    .responsesToggle__box .offersCount {
        top: 9px;
    }
    .PAresumeCollapsed {
        width: 100%;
        height: auto;
    }
    .editResumeModal {
        display: flex;
        justify-content: center;
        padding: 12px 22px;
        background: var(--gr300);
        border-radius: 3px;
        margin-bottom: 8px;
    }
    .editResumeModal p {
        margin-right: 10px;
        color: var(--sec500);
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
    }
    .editResumeModal img {
        width: 17px;
        height: 17px;
    }
    .PAresumeCollapsed__wrap {
        display: flex;
        flex-direction: column;
    }
    .PAresumeCollapsed__text__title {
        display: flex;
        flex-direction: column;
    }
    .PAresumeCollapsed__img {
        width: 100%;
        height: 152px;
        object-fit: cover;
        margin-bottom: 20px;
    }
    .PAresumeCollapsed__text__title__edit {
        display: none;
    }
    .PAresumeCollapsed__text__btnMobil {
        display: flex;
        flex-direction: column;
    }
    .PAresumeCollapsed__text__btnMobil .deleteResume {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #f8e6e6;
        border-radius: 3px;
        padding: 12px 22px;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
    .PAresumeCollapsed__text__btnMobil .deleteResume p {
        color: #c54b4b;
        font-size: 14px;
        font-weight: 500;
    }
    .PAresumeCollapsed__text__price {
        margin-bottom: 24px;
    }
    .PAresumeCollapsed__text {
        width: 100%;
    }
    .PAUserResume .PA__dropContent .dropContent__big {
        width: 100%;
    }
    .resumeCompressBox__wrap {
        display: flex;
        flex-direction: column;
    }
    .vacanciesCompressBox__wrap {
        display: flex;
        flex-direction: column-reverse;
    }
    .resumeCompressBox__img {
        width: 70%;
        margin-bottom: 20px;
    }
    .resumeCompressBox__info {
        width: 100%;
    }
    .resumeCompressBox__title__info {
        width: 100%;
    }
    .resumeCompressBox__text__info {
        margin-bottom: 10px;
    }
    .resumeCompressBox__title__info__box a {
        line-height: 1.4em;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .resumeCompressBox__title__info__box a svg {
        margin: 0 0 10px 0;
    }
    .resumeCompressBox__title__info__box h1 {
        font-size: 20px;
    }
    .vacancyOpen__header .resumeCompressBox__title__info p {
        font-size: 16px;
    }
    .vacancyOpen__header .resumeCompressBox__title__info p span {
        color: var(--sec300);
    }
    .resumeCompressBox__title__info p {
        margin-bottom: 24px;
    }
    .resumeCompressBox__title__info span.colorBtn {
        margin-top: 20px;
        font-size: 12px;
    }
    .resumeCompressBox__text__other {
        flex-wrap: wrap;
    }
    .back_btn {
        margin-top: 20px;
    }
    .resumeCompressBox__text__other p {
        margin-bottom: 8px;
    }
    .resumeCompressBox__btn {
        display: flex;
        flex-direction: column;
    }
    .resumeCompressBox__btn span {
        margin-bottom: 10px;
        width: 100%;
        margin-right: 0;
    }
    .resumeCompressBox__btn a {
        margin-bottom: 20px;
        width: 100%;
        margin-right: 0;
    }
    .resumeCompressBox__btn object a {
        margin-right: 0;
    }
    .resumeCompressBox {
        padding: 20px 24px;
    }
    .resumeCompressBox__tags {
        margin-bottom: 24px;
    }
    .PACompany_responses {
        position: relative;
    }

    .lastVacancies {
        position: relative;
    }
    .lastVacancies .dropContent__small {
        position: absolute;
        top: 59px;
        left: 0;
        transition: 0.2s;
        display: none;
        transition: var(--transitionTime);
    }
    .lastVacancies .dropContent__small .advertising {
        display: none;
    }
    .lastVacancies .dropContent__small__open {
        background: #fff;
        z-index: 10;
        display: flex;
    }
    .lastVacancies .dropContent__small__open .filders {
        width: 100%;
    }
    a.resetSettingsFilter {
        background: #e8ecef;
        border-radius: 3px;
        padding: 12px 22px;
        box-sizing: border-box;
        text-align: center;
    }
    .filterBox__mobil {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #ced2d4;
        margin-bottom: 20px;
    }
    .filterBox__mobil__img {
        display: flex;
        justify-content: flex-end;
        border: 1px solid #ced2d4;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px;
        width: 56px;
    }
    .filterBox__btns__open {
        top: unset;
        bottom: 20px;
    }

    .showFiltersBtn {
        display: none;
    }
    .bodyOverflowHidden {
        overflow-y: hidden;
    }

    .PACompany_responses .dropContent__small {
        position: absolute;
        top: 35px;
        left: 120vw;
        transition: 0.2s;
        display: none;
    }
    .PACompany_responses .dropContent__small__open {
        left: 0;
        background: #fff;
        z-index: 10;
        display: block;
    }
    .PACompany_responses .dropContent__small__open .PAcompanyResponsesCompress {
        max-height: 1200px;
        overflow-y: scroll;
    }
    .responsesMobilArr {
        display: flex;
        align-items: center;
    }
    .responsesMobilArr p {
        font-size: 16px;
        font-weight: 400;
    }
    .PACompany_responses .responsesMobilArr hr {
        width: 100%;
        height: 1px;
        margin: 0 20px;
        border: none;
        background: var(--gr700);
    }
    .responsesMobilArr img {
        width: 34px;
        height: 34px;
    }
    .PACompany_responses hr {
        border: none;
        background: #b3c0c6;
        width: 100%;
        height: 1px;
        margin: 30px 0;
    }
    .PAcompanyResponses__box__price {
        align-items: center;
    }
    .PAcompanyResponses__box__wrap {
        width: 100%;
    }
    .PAcompanyResponses__box__price img {
        width: 27px;
        height: 27px;
    }
    .dropContent {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .searchWork {
        padding: 26px 30px;
        margin-bottom: 0;
    }
    .searchWork__toggle {
        margin-bottom: 4px;
    }
    .formSearch {
        display: flex;
        flex-direction: column;
    }
    .formSearch select {
        width: 100%;
        margin-bottom: 6px;
    }
    .searchWork__input input {
        width: 100%;
        margin-bottom: 6px;
    }
    .resumeOpen {
        display: flex;
        flex-direction: column;
    }
    .dropContent__big {
        width: 100%;
    }
    .resumeOpen__img {
        width: 60%;
        margin-bottom: 40px;
    }
    .resumeOpen__info {
        width: 100%;
    }
    .resumeOpen__box h6 {
        font-size: 14px;
    }
    .resumeOpen__vacancies {
        display: flex;
        flex-direction: column;
    }
    .resumeOpen__vacancies__date {
        margin-bottom: 10px;
    }
    .vacancyOpen__block.resumeCompressBox__btn span {
        margin-right: 0;
        text-align: center;
    }
    .resumeOpenCurrent__title {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }
    .resumeOpenCurrent__title h3 {
        margin-bottom: 14px;
    }
    .resumeOpenCurrent__workPlace__place {
        display: flex;
        flex-direction: column;
    }
    .videoBlock__show {
        height: 230px;
    }
    .vacancyOpen__header__company__wrap {
        align-items: flex-start;
        width: 100%;
    }
    .vacancyOpen__header__company {
        width: 100%;
    }
    .vacancyOpen__header__company__wrap a {
        color: var(--textDim);
    }
    .vacancyOpen__block h5 {
        margin-bottom: 14px;
    }
    .vacancyOpen__block .videoBlock__show {
        width: 100%;
    }
    .postBlock {
        display: flex;
        flex-direction: column;
    }
    .postBlock__box {
        width: 100%;
        margin-bottom: 10px;
    }
    .vacancyOpen__header .resumeCompressBox__btn {
        justify-content: center;
    }
    .vacancyOpen__header .resumeCompressBox__btn span {
        margin-right: 0;
        text-align: center;
    }
    .vacancyOpen__header__company .resumeCompressBox__btn {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .vacancyOpen__header__company__wrap a {
        margin-bottom: 30px;
    }
    .sendResumeModal {
        top: 6px;
        left: 1%;
        width: 98%;
        overflow-y: scroll;
        max-height: 100vh;
        padding: 22px 36px;
    }
    .sendResumeModal__btns .btns {
        width: 100%;
        margin-bottom: 10px;
    }
    .sendAnswerResumeModal {
        top: 20px;
        left: 1%;
        width: 98%;
        padding: 20px 16px;
    }
    .mobilMenu {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        padding: 0 6px;
        box-sizing: border-box;
        background: #fff;
        z-index: 17;
    }
    .mobilMenu__wrap {
        position: relative;
        display: flex;
    }
    .mobilMenu__wrap span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 8px;
        box-sizing: border-box;
        width: 17%;
    }
    .mobilMenu__wrap span svg {
        margin-bottom: 7px;
    }
    .mobilMenu__wrap span p {
        font-size: 14px;
        font-weight: 500;
        color: #252d35;
    }
    .mobilMenu__wrap a {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        border-radius: 8px;
        padding: 0;
        box-sizing: border-box;
        width: 17%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
    }
    .mobilMenu__wrap a img {
        width: 16px;
    }
    .mobilMenu__wrap a p {
        font-size: 14px;
        font-weight: 500;
        color: #252d35;
    }
    .mobilMenu_box.mobilMenu_box__active svg {
        fill: #fff;
        stroke: var(--pri500);
    }
    .mobilMenu_box.mobilMenu_box__active p {
        color: var(--pri500);
    }
    .mobilMenu__wrap .mobilMenuAdd {
        background: var(--pri500);
        border-radius: 50%;
        width: 36px;
        height: 36px;
        transform: translateY(8px);
    }
    .mobilMenuAdd img {
        width: 38.89%;
    }
    .vacancyCompressBox__info .resumeCompressBox__btn {
        display: flex;
        flex-direction: column;
    }
    .mobilMenu__wrap__more {
        display: flex;
        justify-content: space-between;
        padding: 6px;
        box-sizing: border-box;
        position: absolute;
        top: -50px;
        left: 120vw;
        width: 100%;
        background: #fff;
        z-index: 1;
        transition: 0.2s;
    }
    .mobilMenu__wrap__more__open {
        left: 0;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }
    .mobilMenu__wrap__main {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 46px;
        z-index: 5;
    }
    .mobilMenu__wrap__more__wrap {
        display: flex;
        width: 25%;
        justify-content: flex-start;
        flex: 0 0 auto;
        scroll-snap-align: start;
    }
    .mobilMenu__wrap__more__wrap a {
        width: 100%;
    }
    .mobilMenu__wrap__more__wrap a svg {
        width: 16px;
        height: 16px;
    }
    .mobilMenu__wrap__more__wrap span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #252d35;
        width: 100%;
    }
    .sendResumeModal__btns {
        flex-direction: column-reverse;
    }
    .sendResumeModal__btns span,
    .sendResumeModal__btns a {
        margin-bottom: 10px;
        width: 100%;
    }
    .about__info {
        display: flex;
        flex-direction: column-reverse;
    }
    .about__info__text,
    .about__info__img {
        width: 100%;
    }
    .about__info__img {
        margin-bottom: 20px;
    }
    .about__contacts {
        display: flex;
        flex-direction: column;
    }
    .about__contacts__box {
        margin-bottom: 20px;
    }
    .about__contacts__box a {
        width: fit-content;
    }
    .about__contacts__box span {
        margin-bottom: 8px;
    }
    .footer__other__sn a {
        width: 36px;
        height: 36px;
    }
    .mainSearch__search {
        padding: 20px 4%;
    }
    .form__filters span {
        font-size: 16px;
    }
    input.search {
        margin-bottom: 6px;
    }
    .formSearch input {
        width: 100%;
        margin-right: 0;
    }
    .formSearch input[type="submit"] {
        margin-bottom: 12px;
    }
    .formSearch select {
        margin-right: 0;
    }
    .mainSearch__toogle {
        height: 60px;
    }
    .mainSearch__toogle__Btn {
        padding: 20px 24px;
    }
    .mainSearch__toogle__Btn h5 {
        font-size: 14px;
        height: fit-content;
    }
    .areasOfActivity__wrap {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 10px 10px;
        grid-auto-flow: column;
        -webkit-overflow-scrolling: touch;
        gap: 10px 10px;
        padding-bottom: 5px;
    }
    .areasOfActivity__wrap::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }
    .areasOfActivity__wrap::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid transparent;
        background-clip: padding-box;
        border-radius: 7px;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .areasOfActivity__wrap::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    .areasOfActivity__wrap::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    .catBox {
        width: 280px;
        max-width: 280px;
        min-width: 280px;
        box-shadow: 0px 16px 30px rgba(54, 63, 69, 0.1);
        margin: 0;
    }
    .catBox__text {
        white-space: normal;
    }
    .catBox__text p {
        height: 60px;
        max-height: 60px;
        min-height: 60px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .areasOfActivity {
        margin-bottom: 40px;
    }
    .ShowAllCat {
        display: none;
    }
    .login__wrap {
        width: 100%;
    }
    .login__register {
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .login__register hr {
        height: 13px;
        width: 1px;
        border: none;
        background: var(--gr400);
    }
    .login__register p {
        margin-bottom: 6px;
        width: fit-content;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .login__register hr {
        margin: 5px;
    }
    .login__register a {
        font-size: 14px;
    }
    .login__wrap {
        padding: 20px;
    }
    .login__form_btn {
        flex-wrap: wrap;
        justify-content: center;
    }
    .login__form_btn input,
    .login__form_btn p,
    .login__form_btn a {
        margin-bottom: 6px;
    }
    .login__form_btn input {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
        margin-top: 20px;
    }
    .login__form {
        margin-bottom: 0;
    }
    .login__form form input:not([type="submit"]) {
        margin-bottom: 0;
    }
    .login__title {
        margin-bottom: 10px;
    }
    .login__title h1 {
        font-size: 24px;
    }
    .login__form form label {
        display: block;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .login__form_btn {
        margin-top: 0;
    }
    .form__filters__open {
        padding: 15px;
    }
    .mainSearch__search .form__filters__open input[type="submit"] {
        width: 40%;
    }
    .form__filtersBox__checkBox {
        margin-bottom: 20px;
    }
    .mainSearch__search input[type="submit"] {
        width: 100%;
    }
    section.login {
        position: relative;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
    }
    section.register__employer {
        height: auto;
        overflow: scroll;
        align-items: baseline;
    }
    section.register__applicant {
        align-items: baseline;
        overflow: scroll;
        height: fit-content;
    }
    img.absoluteBG {
        width: auto;
        height: auto;
    }
    .vacancyOpenBox {
        display: flex;
        flex-wrap: wrap;
    }
    .addSkillsBox__Block {
        margin-right: 6px;
    }
    .addWorkPlaceModal {
        width: 100%;
        left: 0;
    }
    .car_rights__wrap {
        flex-wrap: wrap;
    }
    .addWorkPlaceModal .personalData__box:nth-child(2) {
        width: 100%;
    }
    .addWorkPlaceModal .personalData__box:nth-child(3) {
        width: 100%;
    }
    .header__account select {
        width: fit-content;
    }
    .header__account {
        display: none;
    }
    .header__account_active {
        display: flex;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
    }
    .header__account_active a {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
    }
    .header__account_active .colorBtn {
        display: none;
    }
    .formSearch__input__wrap {
        margin-right: 0;
    }
    .resumeOpenCurrent__box__inline {
        display: flex;
        flex-wrap: wrap;
    }
    .resumeOpenCurrent__title span {
        width: fit-content;
    }
    .resumeOpenCurrent__box {
        display: flex;
        flex-wrap: wrap;
    }
    .resumeOpenCurrent__box__inline span {
        margin-bottom: 6px;
    }
    .resumeOpenCurrent__box p {
        width: 100%;
    }
    .resumeOpenCurrent__box span {
        width: fit-content;
        margin-bottom: 6px;
    }
    .form__filtersBox__checkBox {
        width: 100%;
    }
    .form__filtersBox__checkBox:first-child ul {
        display: flex;
        flex-direction: column;
    }
    .form__filtersBox__checkBox:first-child ul li {
        width: 100%;
    }
    .form__filtersBox__title__wrap {
        display: flex;
        flex-direction: column;
    }
    .form__filtersBox__title__result {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
    }
    .form__filtersBox__title__result label {
        margin-bottom: 10px;
    }
    .passwordHelper {
        position: inherit;
        top: unset;
        left: unset;
        width: 100%;
        margin: 30px 0;
        margin-top: 0;
        max-width: unset;
    }
    .errorPage__wrap {
        flex-direction: column;
    }
    .errorPage__img {
        display: flex;
        justify-content: center;
        margin-right: 0;
    }
    .errorPage__text {
        width: 98%;
        text-align: center;
    }
    .errorPage__text a {
        margin: 0 auto;
    }
    section.supports .support__wraps {
        width: 100%;
    }
    .support {
        width: 50px;
        height: 50px;
        bottom: 70px;
    }
    .support:hover {
        width: 50px;
    }
    .support:hover p {
        width: 0px;
    }
    .support__wrap img {
        width: 20px;
        height: 20px;
    }
    .mainSearch__postBtn {
        height: 60px;
    }
    .restoreAccess__modal {
        left: 0;
    }
    .PArateBox {
        display: flex;
        flex-direction: column;
    }
    .PArateBox__wrap form {
        display: flex;
        flex-direction: column;
    }

    .PArateBox__left span {
        width: 100%;
    }
    .card {
        text-align: center;
        line-height: 25px;
    }
    .headerSelect__wrap {
        display: none;
    }
    .skillsAdd__input {
        display: flex;
        flex-direction: column;
    }
    .skillsAdd__input input {
        margin-bottom: 10px;
    }
    .ConfirmSubmitModal {
        left: 2.5%;
        width: 95%;
    }
    .header__account__user__mobil {
        display: flex;
    }
    .header__account__user__desctop {
        display: none;
    }
    .header__account__user__drop {
        display: none;
    }
    .PAExitAccount {
        margin-bottom: 20px;
    }
    .PArateBox__left {
        width: 100%;
    }
    .PArateBox__center {
        margin-bottom: 20px;
    }
    .PArateBox__left p,
    .PArateBox__left p span {
        margin-bottom: 18px;
    }
    .PArateBox__right {
        width: 100%;
        margin-bottom: 18px;
    }
    p.resimeCity,
    p.resimeYear {
        white-space: nowrap;
    }
    .resumeCompressBox__btn .btnsDisabled {
        margin: 0;
        margin-right: 0 !important;
        margin-bottom: 10px;
        width: 100%;
    }
    .PACompany_responses .resumeCompressBox {
        background: var(--gr50);
    }
    .verifuMail h1 {
        font-size: 32px;
        line-height: 140%;
    }
    img.targetSearchBGRight {
        width: 100%;
    }
    img.targetSearchBGLeft {
        width: 100%;
    }
    .card-body .PAExitAccount a {
        margin: 0 auto;
    }
    .restore__form .login__form_btn span {
        width: 100%;
        margin-bottom: 10px;
    }
    .restore__form .login__form_btn input {
        margin: 0;
    }
    .restore__form form input:not([type="submit"]) {
        margin-bottom: 20px;
    }
    .resumeVacanciesHideBlock {
        background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.71) 46.88%,
            rgba(255, 255, 255, 0) 100%
        );
    }

    /*MAILINGS*/
    .currentMailing__block {
        flex-direction: column;
    }
    .currentMailing__block .personalData__box {
        width: 100%;
    }
    .mailingTable {
        display: block;
        border: none;
    }
    .mailingTable tr {
        display: block;
        padding: 0;
        margin-bottom: 50px;
    }
    .mailingTable td {
        display: flex;
        padding: 0;
    }
    .mailingTable td p {
        width: 50%;
        background: rgb(182, 182, 182);
        padding: 5px;
        display: block;
    }
    .mailingTable td span {
        font-weight: 400;
        width: 50%;
        background: rgb(236, 236, 236);
        padding: 5px;
        display: block;
    }
    .mailingTable tr td:nth-child(2) {
        width: auto;
        max-width: 100%;
    }
    .mailingTable__title {
        display: none !important;
    }
    .mailingTable__title td,
    .mailingTable__title tr {
        display: none;
    }
    .mailingTable .btns {
        margin: 5px 0;
    }
    /***MAILINGS***/

    /* NEWS & ARTICLES */
    a.newsList__box {
        display: flex;
        flex-direction: column;
    }
    .newsList__box__img,
    .newsList__box__text {
        width: 100%;
    }
    .newsList__box__img img {
        border-radius: 10px 10px 0 0;
    }
    .breadTarget a {
        max-width: 150px;
    }
    .newsList__titleSearch {
        display: flex;
        flex-direction: column;
    }
    .newsList__title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    .newsSearchButton__mobil {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: var(--gr200);
        padding: 12px;
        box-sizing: border-box;
    }
    .newsSearchButton__mobil span {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: black;
        margin-right: 12px;
    }
    .formSearch input[type="submit"] {
        display: none;
    }
    .formSearch input[type="text"] {
        width: 100%;
        padding-left: 42px;
    }
    .formSearch__input__wrap {
        position: relative;
    }
    img.searchIconGrey {
        display: block;
        position: absolute;
        top: 25%;
        left: 16px;
    }
    .newsList__search {
        width: 100%;
        height: 0;
        overflow: hidden;
    }
    .newsList__search__active {
        height: 50px;
    }
    .articlesList__tags {
        margin-top: 0;
    }
    /*** NEWS & ARTICLES ***/

    /* COMPANIES */
    .companiesList__box {
        width: 100%;
    }
    /*** COMPANIES ***/

    /* CURRENT COMPANY */
    .company__info {
        flex-direction: column;
    }
    .company__info__avatar {
        width: 30%;
        min-width: 100px;
        min-height: 100px;
        margin-bottom: 30px;
    }
    .company__info__avatar img {
        width: 100px;
        height: 100px;
    }
    .company__info__text__inline {
        display: flex;
        flex-direction: column;
    }
    .company__info__text__inline .company__info__text__box:last-child {
        margin-bottom: 20px;
    }
    /*** CURRENT COMPANY ***/
    /* OREDERBY */
    .selectBox__date {
        left: unset;
        right: 0;
    }
    /*** OREDERBY ***/
    .telInput__wrap select {
        width: 40%;
    }
    .supportBlock {
        display: none;
    }
    .register__form__box .telInput__wrap select {
        width: 60%;
    }
}
